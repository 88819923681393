.credentialTabView .p-tabview-nav {
    background-color: unset;
}

.credentialTabView .p-tabview-nav .p-tabview .p-tabview-nav {
    background-color: unset;
}

.credentialTabView .p-tabview-nav-link {
    background-color: unset;
}

.credentialTabView .p-tabview-panels {
    padding-left: 0px;
    padding-right: 0px;
}
