.p-panelmenu {

  .p-panelmenu-header {
    outline: 0 none;

    .p-panelmenu-header-content {
      color: $accordionHeaderTextColor;
      background: $accordionHeaderBg;
      border-radius: $borderRadius;
      transition: $listItemTransition;

      .p-panelmenu-header-link {
        color: $accordionHeaderTextColor;
        padding: $accordionHeaderPadding;

        .p-submenu-icon {
          margin-right: $inlineSpacing;
        }

        .p-menuitem-icon {
          margin-right: $inlineSpacing;
        }
      }
    }

    &:not(.p-disabled) {
      &:focus-visible {
        .p-panelmenu-header-content {
          @include focused-inset();
        }
      }
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      .p-panelmenu-header-content {
        background: $accordionHeaderHoverBg;
        border-color: $accordionHeaderHoverBorderColor;
        color: $accordionHeaderTextHoverColor;
      }
    }

    &:not(.p-disabled).p-highlight {
      .p-panelmenu-header-content {
        background: $accordionHeaderActiveBg;
        border-color: $accordionHeaderActiveBorderColor;
        color: $accordionHeaderTextActiveColor;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: 0;
      }

      &:hover {
        .p-panelmenu-header-content {
          border-color: $accordionHeaderActiveHoverBorderColor;
          background: $accordionHeaderActiveHoverBg;
          color: $accordionHeaderTextActiveHoverColor;
        }
      }
    }
  }

  .p-panelmenu-content {
    padding: $verticalMenuPadding;
    background: $accordionContentBg;
    color: $accordionContentTextColor;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;

    .p-panelmenu-root-list {
      outline: 0 none;
    }

    .p-menuitem {
      @include menuitem-link();

      .p-menuitem-content {
        .p-menuitem-link {
          .p-submenu-icon {
            margin-right: $inlineSpacing;
          }
        }
      }
    }

    .p-menuitem-separator {
      border-top: $divider;
      margin: $menuSeparatorMargin;
    }

    .p-submenu-list:not(.p-panelmenu-root-list) {
      padding: $treeNodeChildrenPadding;
    }
  }

  .p-panelmenu-panel {
    margin-bottom: $accordionSpacing;
    width: $menuWidth;

    @if $accordionSpacing == 0 {
      .p-panelmenu-header {
        .p-panelmenu-header-content {
          border-radius: 0;
        }
      }

      .p-panelmenu-content {
        border-radius: 0;
      }

      &:not(:first-child) {
        .p-panelmenu-header {
          .p-panelmenu-header-content {
            border-top: 0 none;
          }

          &:not(.p-highlight):not(.p-disabled):hover,
          &:not(.p-disabled).p-highlight:hover {
            .p-panelmenu-header-content {
              border-top: 0 none;
            }
          }
        }
      }

      &:first-child {
        .p-panelmenu-header {
          .p-panelmenu-header-content {
            border-top-right-radius: $borderRadius;
            border-top-left-radius: $borderRadius;
          }
        }
      }

      &:last-child {
        .p-panelmenu-header:not(.p-highlight) {
          .p-panelmenu-header-content {
            border-bottom-right-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
          }
        }

        .p-panelmenu-content {
          border-bottom-right-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
        }
      }
    }
  }
}
